/* eslint-disable camelcase */
import { useState } from 'react';
import {
  useAnalytics,
  CA_EventLocation,
} from '@cleverrealestate/clever-helpers';

const useGlobalCTAClick = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [fireEvent, actions, contextCreators] = useAnalytics();

  const handleLinkCTAClick = () => {
    setModalOpen(true);
    contextCreators.inlineLinkModal(CA_EventLocation.Inline);
    fireEvent(actions.Step1);
  };

  if (typeof window !== 'undefined') {
    window.globalClick = () => {
      handleLinkCTAClick();
    };
  }

  return [modalOpen, setModalOpen];
};

export default useGlobalCTAClick;
