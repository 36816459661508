/* eslint-disable camelcase */
import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import BasicModal from '@cleverrealestate/clever-components-v2/dist/components/BasicModal';
import UnifiedLanderPage from '@cleverrealestate/clever-components-v2/dist/components/UnifiedLanderPage';
import styles from '@cleverrealestate/clever-components-v2/dist/components/UnifiedLanderPage/UnifiedLanderPage.scss';
import {
  useUserIPGrabber,
} from '@cleverrealestate/clever-helpers';
import SEO from '../components/Seo';
import Layout from '../components/layout';
import useGlobalCTAClick from '../utils/useGlobalCtaClick';
import useGlobalTOCHandler from '../utils/toc.utils';
import renderContent from '../utils/inline-utils-v3';

const LandingPageTemplate = ({ pageContext }) => {
  useUserIPGrabber();
  const {
    // Display Values
    hero_subtitle,
    structured_data,
    hero_gradient_settings,
    category_cards,
    latest_articles,
    generate_archive_pages,
    nav_bar_type,
    base_url,
    page_name,
    pagination_meta,
    current_page,
    page_count,
    // Content Values
    title,
    renderContext,
    // SEO Values
    slug,
    created_at_iso,
    modified_at_iso,
    no_index,
    meta_title,
    meta_description,
    article_image,
    has_cta,
    content_width,
  } = pageContext;
  useGlobalTOCHandler();
  const [modalOpen, setModalOpen] = useGlobalCTAClick();
  const publishDateMeta = [
    {
      name: 'article:published_time',
      content: created_at_iso,
    },
    {
      name: 'article:modified_time',
      content: modified_at_iso,
    },
    {
      name: 'og:updated_time',
      content: modified_at_iso,
    },
  ];
  return (
    <Layout
      colorTransitionStart={-1}
      shadowTransitionStart={50}
      colorTransitionLength={1}
      shadowTransitionLength={125}
      isWhite={nav_bar_type === 'Main Site'}
      showBlogNav={nav_bar_type === 'Blog'}
    >
      <SEO
        pathname={slug}
        title={meta_title}
        description={meta_description}
        canonical={slug}
        noIndex={no_index}
        links={pagination_meta || []}
        publishDateMeta={publishDateMeta}
        breadcrumbs={null}
        structuredData={structured_data}
        image={article_image.url}
        imageHeight={article_image.height}
        imageWidth={article_image.width}
        addWebsiteName={false}
      />
      <UnifiedLanderPage
        title={title}
        heroImageUrl={article_image.url}
        heroSubtitle={hero_subtitle}
        heroGradientSettings={hero_gradient_settings}
        categoryCards={category_cards}
        articles={latest_articles}
        currentPage={current_page}
        pageCount={page_count}
        baseUrl={base_url}
        pageName={page_name}
        generateArchivePages={generate_archive_pages}
        showCTA={has_cta || false}
        contentWidth={content_width || 'default'}
      >
        <article id="article-content" className={styles.mainContent}>
          { renderContent(renderContext.html, renderContext.context) }
        </article>
        {
          modalOpen ? createPortal((
            <BasicModal
              closeDialog={() => setModalOpen(false)}
            />
          ), document.body) : null
        }
      </UnifiedLanderPage>
    </Layout>
  );
};

LandingPageTemplate.propTypes = {
  pageContext: PropTypes.any,
};

LandingPageTemplate.defaultProps = {
  pageContext: {},
};

export default LandingPageTemplate;
